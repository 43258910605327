
.stripeflow {
  background-color:#3e3e42 !important;
  margin-top: 3%;
  margin-left: 3%;
  margin-right: 3%;
}

.subscription-options {
  margin-top: 3%;
}

.serf-card-background {
  background-color: #3e3e42;
  color: white
}

.stripe-text {
  margin-top: 2%;
  color: white !important
}

.stripe-return-box {
  text-align: center;
  height: 30%;
}

.sub-p {
  display: inline;
  margin-left: 10px
}

.bullets {
  padding: 5px;
  font-size: medium;
}

.account-status {
  margin-left: 15%;
  width: 70%;
  padding-top: 4%;
  padding-bottom: 5%
}

.bullets-left {
  padding: 5px;
  font-size: medium;
  text-align: left !important;
}

.bullets-left-no-space {
  padding-left: 5px;
  padding-right: 5px;
  font-size: medium;
  text-align: left !important;
}

.hype-color {
  font-size: large;
  padding-top: 5px;
  color: rgb(147, 159, 201) !important;
  text-align: center !important;
}

.hype-header {
  font-size:x-large;
  padding-top: 2px;
  padding-bottom: 5px;
  color: white !important;
  text-align: center !important;
}

.text-spaced {
  margin-top: 5% !important;
}

.centered {
  text-align: center !important;
}

.thumbnail {
  margin-bottom: 20px;
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail-tall {
  margin-bottom: 20px;
  align-items: center;
  height: 80%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sub-button {
  margin-bottom: 20px !important;
  width: 80% !important;
  margin-left: 10% !important;
}

.return-button {
  width: 20% !important;
  align-items: center;
  text-align: center;
}

.sub-spacer {
  margin-bottom: 20px;
  margin-left: 5% !important;
  width: 90% !important;
}

.sub-separator {
  height: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 90%;
  margin-left: 5%;
  text-align: center;
  background-color: white;
  border: none;
}

.bullets-spacer {
  padding-top: 50px;
}

.bullets-spacer-smaller {
  padding-top: 25px;
}
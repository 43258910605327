.Mui-disabled {
  color:rgb(66, 66, 69) !important
}

.disabled-text {
  color:rgb(66, 66, 69) !important
}

.slider-text {
  color: gray;
  font-size: smaller;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0px !important
}

.placeholder-ranking {
  margin-top: 50px;
  min-height: 500px;
}

.date-radios-label {
  color: gray;
  text-align: center !important;
}

.date-radios-container {
  margin-left: 10% !important;
  margin-right: 10% !important;
  align-items: center !important;
  text-align: center;
  width: 80% !important
}
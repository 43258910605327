
  .login-form-sidenav {
    padding-left: 1%;
    padding-right: 1%
  }

  .login-container {
    align-items: center;
    text-align: center;
    max-width: 800px
  }

  .sidebar-account-state {
    max-height: 700px !important;
    height: 500px;
  }

  .sidebar-container {
    margin-top: 5% !important;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2% !important;
    width: 90% !important;
    text-align: left;
    align-items: center;
    text-align: center !important
  }

  .sidebar-logo {
    margin-bottom:5%
  }

  .sidebar-intro {
    padding-bottom: 0px;
    margin-bottom: 2%;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding-top: 5px;
  }

  .login-control {
    max-width: 400px;
  }

  .login-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");

html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  background-color: #3e3e42;
}

.aboutme-section {
  display: block !important;
}

.aboutme-section-first {
  margin-top: 2%;
}

.aboutme-text {
  color: white;
  padding: 10px;
  font-size: medium;
  text-align: center;
}

.aboutme-card {
  margin-top: 5%;
  color: white;
  background: #1e1e1e;
}

.aboutme-card-jobs {
  margin-top: 5%;
  color: white;
  background: #3e3e42;
}

.aboutme-header {
  background-color: rgb(15, 14, 14);
  color: white;
  font-size:16px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.aboutme-subheader {
  text-align: center;
  padding: 7px;
}

.section-image {
  width: 100%;
  align-items: center !important;
  margin: auto !important;
  justify-content: center !important;
  object-fit: contain;
}

.img-wrapper {
  margin-top: 10px;
}

.img-wrapper-main {
  margin-top: 12%
}
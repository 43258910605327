.date-slider {
  max-width: 90%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.date-slider-container {
  padding-left: 5%;
  padding-right: 5%;
}

.map-bottom {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.mapkey-main {
  min-width: 230px;
  max-width: 500px;
  width: 30%;
  margin-left: auto;
  align-items: right !important;
}

.mapkey-top {
  min-width: 230px;
  max-width: 500px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}

.map-bottom-col {
  padding-bottom: 20px;
}

.map-info {
  width: 95%;
  margin-top: 10px;
}

.map-info-row {
  color: gray;
  font-size: smaller;
  font-style: italic;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.mortgagerates {
  width: 100%;
  text-align: center;
}

.mortgagerates-top {
  width: 100%;
  text-align: center;
}

.MuiGrid2-root {
  margin-top: 0% !important;
}

.css-8jzr7g-MuiGrid2-root {
  margin-top: 0% !important;
}

/* xl */
@media only screen and (min-width: 993px) {
  .mapkey-main {
    margin-top: 2%;
    display: block;
  }
  .mapkey-top {
    display: none;
  }
  .map-bottom-col {
    margin-left: 20px;
  }
  .popslider {
    margin-top: -3%;
    margin-left: 7%;
    margin-right: 7%;
  }
  .statecombo {
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .map-info {
    display: inline-block;
  }
  .mortgagerates {
    width: 100%;
  }
  .mortgagerates-top {
    display: none;
  }
}

/* lg */
@media only screen and (max-width: 992px) {
  .mapkey-main {
    display: none;
  }
  .mapkey-top {
    display: block;
  }
  .map-bottom {
    margin-top: 10px !important;
  }
  .popslider {
    margin-left: 7%;
    margin-right: 7%;
  }
  .statecombo {
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .map-info {
    display: none;
  }
  .mortgagerates {
    display: none;
  }
  .mortgagerates-top {
    display: inline-block;
    margin-bottom: 3%;
  }
}

/* md */
@media only screen and (max-width: 768px) {
  .mapkey-main {
    display: none;
  }
  .mapkey-top {
    display: block;
  }
  .popslider {
    margin-top: 1%;
    margin-left: 7%;
    margin-right: 7%;
  }
  .statecombo {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .map-info {
    display: none;
  }
  .mortgagerates {
    display: none;
  }
  .mortgagerates-top {
    display: inline-block;
    margin-bottom: 3%;
  }

}

/* sm */
@media only screen and (max-width: 576px) {
  .keymap-center {
    display: none;
  }
  .mapkey-top {
    display: block;
  }
  .popslider {
    margin-top: 1%;
    margin-left: 7%;
    margin-right: 7%;
  }
  .statecombo {
    margin-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .map-info {
    display: none;
  }
  .mortgagerates {
    display: none;
  }
  .mortgagerates-top {
    display: inline-block;
    margin-bottom: 3%;
  }
}

.cards-container {
  margin-left: 2px !important;
}

.mortgage-text {
  color: #d3d3d3;
  font-size: 12pt;
  text-align: center !important;
  display: inline !important;
}

.rate-worst {
  color: #FF0000 !important;
  margin-left: 3px;
}
.rate-best {
  color: #10FF00 !important;
  margin-left: 3px;
}
.rate-good {
  color:#FFE000 !important;
  margin-left: 3px;
}
.rate-mid {
  color: #FF7000 !important;
  margin-left: 3px;
}

.watchlist-button {
  width:30px;
  height:30px;
  align-items: center;
  padding: 5px !important;
}

.watchlist-icon {
  font-size: medium;
  text-align: center;
  margin-top: -10px;
}

.watchlist-row {
  color: white;
  background-color: #2d2d30;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  height: auto;
  min-height: 40px;
  border-bottom: 1px solid black;
  text-align: center;
}

.watchlist-row-page:hover {
  background-color: #1e1e1e;
}

.watchlist-row-page {
  color: white;
  background-color: #2d2d30;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  height: auto;
  min-height: 40px;
  border-bottom: 1px solid black;
  text-align: center;
}


.watchlist-row:hover {
  background-color: #1e1e1e;
}

.listing-row:hover {
  background-color: #1e1e1e;
}

.listing-row-selected {
  background-color: #1e1e1e !important;
}

.watchlist-link-wrapper {
  color: white;
  text-align: center !important;
  align-items: center !important;
  vertical-align: middle !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.watchlist-link {
  color: white;
  text-decoration: none;
}

.watchlist-geo {
  color: white;
  text-align: center !important;
  align-items: center !important;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12pt;
  overflow: auto;
  height: auto;
  text-decoration: none;
  display: block;
  cursor: pointer;
  overflow-x:hidden;
}

.watchlist-listing {
  color: white;
  text-align: center !important;
  align-items: center !important;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 10pt;
  overflow: hidden;
  height: auto;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.watchlist-row-selected {
  background-color: #3e3e42;
  color: white;
  border: 0px solid black
}

.watchlist-row-page-selected {
  background-color: #1e1e1e;
  color: white;
  border: 0px solid black
}

.watchlist-value {
  padding-top: 5px;
  padding-bottom: 5px;
}

.watchlist-good {
  color: green;
}

.watchlist-bad {
  color: red;
}

.watchlist-normal {
  color: white;
}

.listings-container {
  margin-top: 4%;
  border-radius: .375rem;
  height: 100%;
  overflow: auto;
}

.watchlist-sidebar-container {
  margin-top: 4%;
  border-radius: .375rem;
  overflow: auto;
  overflow-x: hidden;
}

.watchlist-body {
  overflow: auto;
  overflow-x:auto;
  padding-bottom: 2% !important;
}

.watchlist-sidebar-page {
  margin-top: 4%;
  border-radius: .375rem;
  height: 100%;
  overflow-x: hidden;
  background-color: #1e1e1e;
}

.maprow-watchlist {
  background-color: #1e1e1e;
}

.watchlist-page-header {
  background-color: #3e3e42;
  color: white;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 8px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid black;
  border-radius: .375rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.sidebar-header {
  background-color: #3e3e42;
  color: white;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 8px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid black;
  border-radius: .375rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.listings-header {
  background-color: #3e3e42;
  color: white;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 15px;
  border-bottom: 1px solid black;
  border-radius: .375rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.listing-row {
  color: white;
  background-color: #2d2d30;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid black;
  text-align: center;
}

.watchlist-page-title {
  background-color: #3e3e42;
  color: white;
  border-radius: .375rem;
}

.sidebar-title {
  background-color: #3e3e42;
  color: white;
  border-radius: .375rem;
}

.watchlist-header-txt {
  color: white;
  text-align: center;
  vertical-align: center;
  font-size: 15pt;
}

.watchlist-header-subtxt {
  color: white;
  text-align: center;
  vertical-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
  font-size: 10pt !important;
  padding-bottom: 1%;
  padding-top: 1%;
}


.watchlist-combo {
  font-size: 4px;
  color: white;
  padding-bottom: 2%;
  background-color: #3e3e42 !important;
}

.separator-watchlist {
  height: 2px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background-color: white;
  border: none;
  align-items: center;
}

.watchlist-show {
  display: block
}

.watchlist-hide {
  display: none
}

.listing-combobox {
  width: 40%;
  margin-left: 5%;
  margin-right: 5%;
}

.listing-median {
  width: 40%;
  margin-left: 55%;
  margin-right: 5%;
  color: white;
}

.listing-deals-viewer {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.listing-deals-container {
  overflow: auto;
  overflow-x: hidden;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  max-height: 750px;
}

.iframe-container-watchlist iframe {
  position: relative;
  margin-top: -7rem;
  min-width: 670px;
  width: 100%;
}

.iframe-outer-container-watchlist {
  overflow: hidden;
}

.iframe-actual-watchlist {
  background-color: white;
  min-height: 500px;
}

.loader-centered {
  margin-left: 45%
}

.sidenav {
  min-height: max-content !important
}

.intro-text {
  margin-top: 10px;
  color: white;
  text-align: center;
}

.paywall-spacer {
  margin-top: 20px;
  color: white;
  text-align: center;
}

.profile-header {
  background-color:#3e3e42;
  color: white;
  height: 20%;
  margin-top: 2%;
  margin-right: 1%;
  margin-left: 1%
}

.profile-info {
  margin-top: 5%;
  margin-left: 3%;
  margin-right: 3%;
  text-align: center;
}

.profile-text {
  padding-bottom: 2%;
  text-align: left;
}

.profile-text-urgent {
  padding-bottom: 2%;
  text-align: left;
  color: red;
}

.profile-buttons {
  margin-top: 20px;
}

.prof-button {
  min-width: 150px;
  min-height: 60px;
  align-items: center;
  margin-bottom: 20px;
}

.prof-button-a {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
}

.areyousure {
  width: 50%;
  margin-left: 25%
}

.left {
  text-align: left;
  align-items:normal
}

.profile-page-as {
  align-items: center;
  margin-top: 2%;
  margin-bottom: auto;
}

.form-control {
  background-color: #212529 !important
}

.profile-sub-button {
  margin-top: 20px !important;
  width: 80% !important;
  margin-left: 10% !important;
}

.btn-primary {
  --bs-btn-bg: rgb(69, 69, 69) !important;
  --bs-btn-hover-bg: #1e1e1e !important;
  --bs-btn-active-bg: gray !important;
  border: 0
  
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control {
  border-color: gray
}

.form-control:focus {
  border-color: white;
  border: 1
}

.middle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

}

.vertical-center {
  vertical-align: middle;
  align-items: center;
}

.vertical-center-special {
  text-align: center !important;
  vertical-align: middle !important;
  margin-top: 10% !important;
  margin-bottom: 10% !important;
}

.color-settings {
  margin-left: 5%;
}

.logged-out-text {
  margin-bottom: 10px;
}

.map-key-wrapper-prof {
  margin-top: 25%;
  margin-bottom: 25%;
}

.logged-out-color-settings {
  padding: 2%;
  margin-top: 2%;
}

/* md */
@media only screen and (max-width: 768px) {
  .profile-text {
    font-size: medium;
  }
}

/* sm */
@media only screen and (max-width: 576px) {
  .profile-text {
    font-size: medium;
  }
  .prof-button {
    max-width: 150px;
    width: 100px;
    min-height: 60px;
    align-items: center;
    margin-bottom: 20px;
  }
  .vertical-center-special {
    text-align: center !important;
    vertical-align: middle !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .profile-info {
    margin-top: 5%;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
  }
}
html {
  font-family: system-ui, sans-serif;
  font-size: 12;
}

body {
  margin: 0;
  padding: 0;
  background-color: #2d2d30 !important
}

::selection {
  color: white;
  background-color: #1e1e1e;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.text-centered {
  text-align: center !important
}

.text-left {
  text-align: left !important
}

.pros-text{
  color: #d3d3d3;
  font-size: 10pt;
  font-style: italic;
  margin-top: 5px;
}

.center-obj {
  margin-left: auto !important;
  margin-right: auto !important;
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  width: auto !important;
}

.gauge-text {
  margin-top: 0px;
  margin-bottom: 0px;
}

.center {
  justify-content: center;
  align-items: center;
}

.chart-centered {
  margin-bottom: 5%;
  text-align: center !important
}

.chart-row {
  margin-top: 3%
}

.community-chart-centered {
  margin-top: 5%;
  text-align: center !important
}

.headRow {
  background: '#2E3B55' !important;
  color: white
}

.flex-parent {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.flex {
  flex: 1;
}

.tabs-center {
  justify-content: center;
  align-items: center;
}

.card-background {
  color: white;
  background: #1e1e1e;
}

.card-background-alt {
  color: white;
  background: #2d2d30;
}

.card-background-fire {
  background: rgb(145, 5, 5);
  color: white;
}

.card-background-drought {
  background:rgb(201, 191, 0);
  color: white;
}

.card-background-heat {
  background: rgb(241, 137, 0);
  color: white;
}

.card-background-storm {
  background: rgb(0, 11, 158);
  color: white;
}

.card-background-flood {
  background: rgb(0, 72, 117);
  color: white;
}

.card-background-green {
  color: white;
  margin-bottom: 15px;
  background: rgb(0, 120, 28) !important;
}

.card-background-winter {
  color: white;
  background:rgb(0, 21, 252) !important
}

.card-background-summer {
  color: black !important;
  background:rgb(253, 252, 116) !important
}

.card-header-summer {
  color: black !important;
  background:rgb(220, 220, 75) !important
}

.card-header-winter {
  color: white !important;
  background:rgb(0, 17, 205) !important
}

.card-stats {
  background: #1e1e1e;
  color: white
}

.card-mortgage {
  color: #d3d3d3 !important;
  font-style: italic;
  
}

.card-header {
  color: white;
  font-size:16px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}

.ch-alt {
  background-color: rgb(29, 29, 31);
}

.ch-normal {
  background-color: rgb(15, 14, 14);
}

.card-header-fire {
  background: rgb(104, 0, 0) !important;
}

.card-header-drought {
  background: rgb(189, 186, 0) !important;
}

.card-header-heat {
  background: rgb(195, 117, 0) !important;
}

.card-header-storm {
  background: rgb(0, 6, 87);
}

.card-header-flood {
  background: rgb(0, 144, 234);
}

.card-subheader {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  padding-bottom: 7px;
}

.separator {
  height: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
  margin-right: 25px;
  text-align: center;
  background-color: white;
  border: none;
}

.separator-alt {
  height: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
  margin-right: 25px;
  text-align: center;
  background-color: rgb(32, 38, 57);
  border: none;
}

.iframe-container iframe {
  position: relative;
  margin-top: -4rem;
}

.iframe-outer-container {
  overflow: hidden;
  min-height: 700px;
}

.iframe-actual {
  margin-left: 2%;
  width:96%;
  background-color: white;
  min-height: 700px;
}

.iframe-actual-photos {
  margin-left: 10%;
  width:80%;
  background-color: white;
  min-height: 900px;
}

.zero-margins {
  margin-right: 0px;
  padding-right: 0px;
  width: auto;
}

.people-pies {
  margin-left: 0%;
}

.good-indicators {
  padding-left: 10px;
}

.mapkey-link:hover {
  cursor: pointer;
  color: white !important;
}

.mapkey-link {
  text-decoration: none;
}

.light-text {
  color: white
}

.same-line {
  display: flex
}

.strong-indicator {
  color: #90ee90
}
.weak-indicator {
  color: #ffff00
}
.card-key {
  padding:10px
}
.bold {
  font-weight: bold;
}
.map-color-worst {
  color: #BF0000
}
.map-color-best {
  color: #10FF00
}
.map-color-good {
  color:#FFE000
}
.map-color-mid {
  color: #FF7000
}

.colorblind-map-color-worst {
  color: #2d4a76;
}
.colorblind-map-color-best {
  color: #7b0000;
}
.colorblind-map-color-good {
  color:#FF9A98
}
.colorblind-map-color-mid {
  color: #80c3d8
}

.dark-main-color {
  background-color: #1e1e1e !important;
}
.dark-main-text {
  color: #1e1e1e !important;
}
.dark-primary {
  color: white !important;
}
.light-main-color {
  background-color: rgb(147, 159, 201) !important;
}
.dark-font-color {
  color: white !important;
}

.showallstates {
  color: white !important;
}

.ranking-title {
  color: rgb(147, 159, 201) !important
}

.showallstates .showallstates:hover {
  background-color: transparent !important;
  color: #3e3e42;
  box-shadow: None;
}

.nav-tabs .nav-item .nav-link {
  background-color: #1e1e1e;
  border: 0px solid transparent !important;
  border-bottom: 0px;
  color: #FFF;
}

.nav-tabs .nav-item .nav-link.active {
  color: white;
  border: 0px solid transparent !important;
  background-color: #3e3e42;
}

.nav-tabs {
  border: 0px;
}

.nav-tabs .nav-link:hover {
  background-color: #3e3e42;
}

.tab-content {
  background-color: #3e3e42;
  border-radius: .375rem;
  padding-top: 15px;
  padding-left: 0px;
  padding-right: 0px;
  border-top: 0px solid transparent !important;
}

.css-1reot9s {
  background-color: #3e3e42;
}

.tab-background {
  background-color: #3e3e42;
}

.spacer {
  margin-bottom: 20px;
  margin-right: 0px;
}

.small-spacer {
  margin-bottom: 15px;
}

.spacer-no-bottom {
  margin-right: 0px;
  padding-right: 0px;
}

.controls {
 color: white !important;
}

.no-margins {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.tab-content .tab-pane {
  background-color: #3e3e42;
  color: white;
  min-height: 200px;
  height: auto;
  padding-bottom: 100px;
  padding-top: 0px !important;
}

.pie-str {
  height: 200px
}

.climate-card {
  margin-top: 20px;
  padding: top 20px;
}

.MuiFormControl {
  color: white !important
}

.combobox-dark {
  color: transparent !important;
  border-color: #1e1e1e !important;
/*  background-color: #3e3e42 !important;*/
  accent-color: #1e1e1e !important;
}

.MuiOutlinedInput-notchedOutline {
  color: white !important;
  border-color: white !important;
}

.MuiFormLabel-root {
  color: white !important;
  border-color: #1e1e1e !important;
  /*background-color: #3e3e42 !important;*/
}

input {
  color: white !important
}

.MuiSlider-root {
  color: #1e1e1e !important
}

.MuiSlider-mark {
  background-color: #3e3e42 !important
}

.Mui-selected {
  background-color: #1e1e1e !important;
  color: white !important

}

.button[aria-pressed="false"] {
  background-color: #3e3e42 !important;
  color: white
}

.button[aria-pressed="true"] {
  background-color: "green" !important;
  background: "green" !important;
  color: white
}

.css-6x4ptp-MuiButtonBase-root-MuiToggleButton-root[aria-pressed="false"] {
  background-color: rgba(63, 63, 67, 25%) !important;
  color: white
}

.css-6x4ptp-MuiButtonBase-root-MuiToggleButton-root[aria-pressed="true"] {
  background-color: #1e1e1e !important;
  color: white
}

.css-1xgjc5y-MuiButtonBase-root-MuiToggleButton-root[aria-pressed="false"] {
  background-color: rgba(63, 63, 67, 25%) !important;
  color: white
}

.css-1xgjc5y-MuiButtonBase-root-MuiToggleButton-root[aria-pressed="true"] {
  background-color: #1e1e1e !important;
  color: white
}

.MuiButtonBase-root[aria-pressed="false"] {
  background-color: rgb(69, 69, 69) !important;
  color: white
}

.MuiSlider-markLabel {
  color: white !important;
  font-size: small !important

}

.MuiPopper-root {
  background: #3e3e42 !important;
  background-color: #3e3e42 !important;
  color: white !important
}

.MuiInputBase-root {
  background: transparent !important;
}

.MuiToggleButton-primary {
  background: #3e3e42;
  background-color: #3e3e42;;
}

.MuiTableCell-root {
  color: white !important
}

.map-key-lg {
  max-width: 15% !important;
  width: 15% !important;
  position:absolute;
}

.no-margins {
  margin: 0px;
  padding: 0px;
}

.map-wrapper {
  margin-top: -5%
}

.mapkey {
  position: absolute;
  width: 15%;
  margin-top: -3%
}

.tabs-margin {
  margin-top: 1%;
  margin-left: 1%;
}

.col-lg-2 {
  padding-right:0%
}

.keymap-left {
  margin-left: 5%;
  margin-right: auto;
  align-items: center;
}

.paywall-subset {
  margin-top: 1%;
  margin-left: 1%
}

.profile-subset-button {
  width: 80% !important;
  margin-left: 10% !important;
}

.left-on-lg {
  margin-left: auto !important;
  margin-right: auto !important;
  align-items: center !important;
  text-align: center !important;
}

/* xl 
@media only screen and (min-width: 993px) {
}*/

/* lg */
@media only screen and (max-width: 992px) {
  .iframe-actual-photos {
    margin-left: 2%;
    width:96%;
    background-color: white;
    min-height: 700px;
  }
  .iframe-container iframe {
    position: relative;
    margin-top: 0rem;
  }
  .left-on-lg {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: left !important;
  }
}

/* md */
@media only screen and (max-width: 768px) {
  .iframe-actual-photos {
    margin-left: 2%;
    width:96%;
    background-color: white;
    min-height: 700px;
  }
  .iframe-container iframe {
    position: relative;
    margin-top: 0rem;
  }
}

/* sm */
@media only screen and (max-width: 576px) {
  .iframe-actual-photos {
    margin-left: 2%;
    width:96%;
    background-color: white;
    min-height: 700px;
  }
  .iframe-container iframe {
    position: relative;
    margin-top: 0rem;
  }
  .center-sm {
    margin-left: auto !important;
    margin-right: auto !important;
    align-items: center !important;
    text-align: center !important;
  }
  .MuiSlider-markLabel {
    font-size: 0.375rem !important
  }
  .tooltip-element:hover .tooltip {
    opacity: 0;
  }
  
}

.tab-buttons {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.center-combobox {
  margin-left: auto !important;
  margin-right: auto !important;
  align-items: center !important;
  text-align: center !important;
}

.center-card {
  margin-left: auto !important;
  margin-right: auto !important;
  align-items: center !important;
  text-align: center !important;
}

.zoomout {
  margin-top: 10px !important;
  background: #3e3e42 !important;
}

.zoomout:hover {
  background: #1e1e1e !important;
}

.subsets-row:hover {
    background-color: #1e1e1e !important;
}

.ai-under-text {
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.gauge-link {
  color: white !important;
  text-decoration: none;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  align-items: center !important;
}

.gauge-link:hover {
  cursor: pointer;
}

.tooltip-element {
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 10ms;
  top: 100px;
  left: 0px;
  max-width: 200px;
  overflow: auto;
  width: max-content;
}

.tooltip-bottom {
  top: 105%;
  left: 0;
}

.tooltip-element:hover .tooltip {
  opacity: 1;
}

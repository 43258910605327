
.leaderboard-spacer {
  margin-top: 1%
}

.leaderboard-col {
  padding-right: 2%;
  margin-top: 15px;
}

.leaderboard-col-first {
  padding-left: 2%;
  padding-right: 1%;
  margin-top: 15px;
}

@media only screen and (min-width: 992px) {
  .leaderboard-col {
    padding-right: 0%;
    margin-top: 0px;
  }

  .leaderboard-col-first {
    padding-left: 2%;
    padding-right: 0%;
    margin-top: 0px;
  }
}

.centered-items {
  margin-left: auto;
  margin-right: auto;
  align-items: center !important;
  text-align: center;
}

.leaderboard-state-col {
  margin-top: 15px
}

.leaderboard-chart-spacer {
  padding-top: 5%;
}

.leaderboard-header {
  color: white;
  font-size: 12 pt;
}

.leaderboard-row:hover {
  cursor: pointer;
  background-color: #1e1e1e !important;
}

.leaderboard-row-selected {
  background-color: #1e1e1e !important;
}

.spacer-rankings {
  padding: 5px;
}
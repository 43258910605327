
@media only screen and (min-width: 992px) {
  .leaderboard-col {
    padding-right: 0%;
    margin-top: 0px;
  }

  .leaderboard-col-first {
    padding-left: 2%;
    padding-right: 0%;
    margin-top: 0px;
  }
}

.centered-items {
  margin-left: auto;
  margin-right: auto;
  align-items: center !important;
  text-align: center;
}

.link-wrapper {
  margin-top: 2%;
  color: white;
  width: 95%;
}

.link-item {
  padding: 10px;
  color: white;
  font-size: small;
  text-align: left;
}

.state-item {
  padding-right: 10px;
  color: white;
  font-size: small;
}

.state-header {
  padding: 10px;
  font-size: medium;
  text-align: left;
}

.state-list {
  padding-bottom: 20px;
}

.downloads-datepicker {
  background-color:#3e3e42 !important;
}

.MuiPickersLayout-root {
  background-color:#3e3e42 !important;
  color: white !important;
  accent-color: white
}

.css-4pax7l-MuiPickersLayout-root {
  background-color:#3e3e42 !important;
  color: white !important;
  accent-color: white
}

.MuiPickersDay-root {
  color: white !important
}

.MuiDayCalendar-weekDayLabel {
  color: white !important
}

.MuiTypography-root {
  color: white !important
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
  color: white !important
}

.MuiPickersDay-root:not(.Mui-selected) {
  color: white !important
}

.MuiDayCalendar-header {
  color: white !important
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  color: white !important
}

.css-1hbyad5-MuiTypography-root {
  color: white !important
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  color: white !important
}

.MuiButtonBase-root {
  color: white !important
}

.Mui-disabled {
  color: gray !important
}

.disabled-text {
  color: white !important;
  margin-top: 10px;
}

.download-button {
  margin-left: auto !important;
  margin-right: auto !important;
  align-items: center !important;
  display: flex;
}
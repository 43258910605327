code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3e3e42;
  overflow-x: hidden;
  overflow-y: hidden;
}

html {
  height: -webkit-fill-available;
  min-height: 100%;
  overflow-x: hidden;
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -2.5em; /* the bottom margin is the negative value of the footer's height */
  position: relative;
  z-index: 0;
}